import * as echarts from 'echarts'
import engageIcon from 'src/assets/images/study/engage.png'
import exploreIcon from 'src/assets/images/study/explore.png'
import enlightenIcon from 'src/assets/images/study/enlighten.png'
import elaborateIcon from 'src/assets/images/study/elaborate.png'
import evaluateIcon from 'src/assets/images/study/evaluate.png'
import extendIcon from 'src/assets/images/study/extend.png'
import circleCat from 'src/assets/images/study_cat.png'

// 正常状态
function getNormalItemData(id: number, fontSize: number, color: string) {
  return {
    formatter: [`{name|${id}}`].join('\n'),
    position: 'inner',
    rich: {
      name: {
        fontWeight: 600,
        fontSize: fontSize,
        color: color,
      },
    },
  }
}

// 高亮状态
function getEmphasisItemData(iconSize: number, icon: any) {
  return {
    formatter: ['{image|}'].join('\n'),
    rich: {
      image: {
        with: iconSize,
        height: iconSize,
        backgroundColor: {
          image: icon,
        },
      },
    },
  }
}

export function getStudyEchart(width: number) {
  const docWidth = document.documentElement!.clientWidth
  // ui图 chart 宽度
  const chartWidth: number = 366
  const ratio: number = width / chartWidth
  const originCircleWH: number = 160
  const originFontSize: number = 38
  const originIconSize: number = 50
  const originScaleSize: number = 15

  const circleWH: number = parseInt(`${ratio * originCircleWH}`)
  const fontSize: number = parseInt(`${ratio * originFontSize}`)
  const iconSize: number = parseInt(`${ratio * originIconSize}`)
  const scaleSize: number = parseInt(`${ratio * originScaleSize}`)
  console.log('circleWH', circleWH, fontSize, iconSize, scaleSize)

  const normalData = [
    getNormalItemData(1, fontSize, '#3F8CF1'),
    getNormalItemData(2, fontSize, '#FFD304'),
    getNormalItemData(3, fontSize, '#FF322D'),
    getNormalItemData(4, fontSize, '#7E5DF7'),
    getNormalItemData(5, fontSize, '#FF7807'),
    getNormalItemData(6, fontSize, '#94D656'),
  ]

  const emphasisData = [
    getEmphasisItemData(iconSize, engageIcon),
    getEmphasisItemData(iconSize, exploreIcon),
    getEmphasisItemData(iconSize, enlightenIcon),
    getEmphasisItemData(iconSize, elaborateIcon),
    getEmphasisItemData(iconSize, evaluateIcon),
    getEmphasisItemData(iconSize, extendIcon),
  ]

  return {
    tooltip: {
      trigger: 'item',
      show: false,
    },
    graphic: {
      // 图形中间图片
      elements: [
        {
          type: 'image',
          style: {
            image: circleCat, // 你的图片地址
            width: (128 * docWidth) / 1920,
            height: (153 * docWidth) / 1920,
          },
          left: 'center',
          bottom: '27%',
        },
      ],
    },
    series: [
      {
        name: 'study module',
        type: 'pie',
        radius: ['45%', '88%'],
        avoidLabelOverlap: false,
        padAngle: 5,
        itemStyle: {
          borderRadius: 10,
          color: '#fff',
        },
        startAngle: 120,
        data: [
          {
            value: 100,
            id: 1,
            label: {
              normal: normalData[0],
              emphasis: emphasisData[0],
            },
          },
          {
            value: 100,
            id: 2,
            label: {
              normal: normalData[1],
              emphasis: emphasisData[1],
            },
          },
          {
            value: 100,
            id: 3,
            label: {
              normal: normalData[2],
              emphasis: emphasisData[2],
            },
          },
          {
            value: 100,
            id: 4,
            label: {
              normal: normalData[3],
              emphasis: emphasisData[3],
            },
          },
          {
            value: 100,
            id: 5,
            label: {
              normal: normalData[4],
              emphasis: emphasisData[4],
            },
          },
          {
            value: 100,
            id: 6,
            label: {
              normal: normalData[5],
              emphasis: emphasisData[5],
            },
          },
        ],
        emphasis: {
          // 高亮样式
          scale: true, // 是否启用缩放动画效果
          scaleSize: scaleSize,
          itemStyle: {
            // color: '#FF322D',
            color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
              {
                offset: 0,
                color: 'rgba(255,50,45,1.0)',
              },
              {
                offset: 1,
                color: 'rgba(255,125,0,1.0)',
              },
            ]),
          },
        },
      },
    ],
  }
}
