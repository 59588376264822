import { FC, useEffect } from 'react'
import { reportEvent } from 'src/utils/sensors'
import { PAGE_EXPOSED_EVENT, EModuleID, EFromID } from 'src/constant/config'
import useAppStore from 'src/store/index'
import styles from './index.module.scss'

/**
 * 视频模块
 */
export const Index = () => {
  const {
    soundOpen,
    setSoundOpen,
    videoStop,
    setVideoStop,
    showRegisterDialog,
    showQrcodeDialog,
    isLogin,
    setShowRegisterDialog,
    setRegisterDialogFrom,
  } = useAppStore()

  useEffect(() => {
    const video: HTMLVideoElement = document.getElementById('video') as HTMLVideoElement
    if (video) {
      if (videoStop) {
        video.pause()
      } else {
        video.play()
      }
    }
  }, [videoStop])

  const handleVideoEnd = () => {
    console.log('end')

    if (!isLogin && !showRegisterDialog && !showQrcodeDialog) {
      setShowRegisterDialog(true)
      setVideoStop(true)
      setRegisterDialogFrom(EFromID.VIDEO)
    } else {
      const video: HTMLVideoElement = document.getElementById('video') as HTMLVideoElement
      video.currentTime = 0 // 设置从头开始
      video.play()
    }
  }
  // 声音点击
  const onSoundClick = () => {
    const video: HTMLVideoElement = document.getElementById('video') as HTMLVideoElement
    if (video) {
      video.muted = false
      setSoundOpen(true)
    }
  }

  const onVideoClick = () => {
    if (!soundOpen) {
      // 开启声音
      setSoundOpen(true)
      return
    }
    setVideoStop(true)
  }

  const onStopClick = () => {
    setVideoStop(false)
  }

  return (
    <div className={styles.page} id={EModuleID.VIDEO_PAGE}>
      <div className={styles.contentWrap}>
        {/* <div className={styles.relative}> */}

        {/* </div> */}
        <div className={styles.videoWrap}>
          <div className={styles.videoBox}>
            <video
              id="video"
              className={styles.video}
              onClick={onVideoClick}
              src="https://education.codemao.cn/27/public-edu/1/1712458374000/codemao9.mp4"
              autoPlay
              muted={!soundOpen}
              onEnded={handleVideoEnd}
              // loop
            />
            {!soundOpen && <div className={styles.soundBtn} onClick={onSoundClick} />}
            {videoStop && (
              <div className={styles.stopMask} onClick={onStopClick}>
                <div className={styles.icon} />
              </div>
            )}
            <div className={styles.element1} />
            <div className={styles.element2} />
          </div>
        </div>
        <div className={styles.title}>九年耕耘，初心不改</div>
        <div className={styles.subTitle}>
          <span className={styles.decr}>用心做教育，把爱给孩子</span>，让编程教育走到更多孩子身边
        </div>
      </div>
    </div>
  )
}
