import { FC } from 'react'
import { IStudyInfo, IStudyInfoProps } from 'src/apis/types'
import hover1 from 'src/assets/images/study_hover1.png'
import TextWrap from '../text-wrap'
import styles from './index.module.scss'

const studyInfo: IStudyInfo = {
  id: 1,
  lightImg: hover1,
}

// 上边信息
const TopInfo: FC<IStudyInfoProps> = (props: IStudyInfoProps) => {
  const { id } = props

  return (
    <div className={styles.board}>
      <div className={styles.content}>
        <TextWrap title="唤醒 Engage" subTitle2="激发学习动机和兴趣" subTitle1="创设主题情境" />
      </div>
      <div className={styles.leftLogo} />
      <div className={styles.rightLogo} />
      {id === studyInfo.id && (
        <div className={styles.tips}>
          <img src={studyInfo.lightImg} alt="" className={styles.light} />
        </div>
      )}
    </div>
  )
}

export default TopInfo
