import { FC } from 'react'
import { reportEvent } from 'src/utils/sensors'
import { PAGE_EXPOSED_EVENT, EModuleID, PAGE_MODULECLICK_EVENT, EFromID } from 'src/constant/config'
import image from 'src/assets/images/video_cat.png'
import useAppStore from 'src/store'
import pic1 from 'src/assets/images/footer_pic1.png'
import pic2 from 'src/assets/images/footer_pic2.png'
import styles from './index.module.scss'

/**
 * 底部栏
 */
export const Index = ({ currentModule }: { currentModule: EModuleID }) => {
  const { setShowRegisterDialog, isLogin, setShowQrcodeDialog, setRegisterDialogFrom } = useAppStore()

  function onEntityClick() {
    if (isLogin) {
      setShowQrcodeDialog(true)
    } else {
      setShowRegisterDialog(true)
      setRegisterDialogFrom(EFromID.BOTTOM)
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.title}>编程猫，让孩子享受创造的乐趣!</div>
      <div className={styles.connect}>联系电话：400-835-3800 </div>
      <div className={styles.connect}>地址：广东省深圳市前海深港合作区南山街道兴海大道3044号信利康大厦</div>
      <div className={styles.text}>深圳点猫科技有限公司 ©2015-2022 粤ICP备 15030912 号-5</div>
      <div className={styles.imgWrap}>
        <img src={pic1} alt="" />
        <img src={pic2} alt="" />
      </div>
      <div className={styles.extra}>以上数据来源于编程猫数据研究院，截止于2024年3月</div>
      {![EModuleID.HOME_PAGE].includes(currentModule) && (
        <div className={styles.footer}>
          <img src={image} alt="" />
          <span className={styles.des}>免费领取试听课</span>
          <button className={styles.btn} onClick={onEntityClick}>
            让我家孩子也试试
          </button>
        </div>
      )}
    </div>
  )
}
