import { FC, useEffect, useRef, useState } from 'react'
import { EEventID, EFromID, EModuleID, HOME_VIDEO_CACHE_KEY, PAGE_MODULECLICK_EVENT } from 'src/constant/config'
import useAppStore from 'src/store/index'
import { Button, Form, Input, message } from 'antd'
import { checkCode, checkPhone, valDeal } from 'src/components/register-dialog'
import { errorParse, isSmsCodeError } from 'src/utils/error-parse'
import { useForm } from 'antd/es/form/Form'
import { checkPhoneNumber } from '@frontend/doraemon'
import { callCaptcha } from 'src/utils/iris'
import { debounce } from 'lodash-es'
import { getAuthCode, registry } from 'src/apis/app'
import { reportEvent } from 'src/utils/sensors'
import down1 from 'src/assets/images/down1.png'
import down2 from 'src/assets/images/down2.png'
import down3 from 'src/assets/images/down3.png'
import styles from './index.module.scss'

const btnNames: string[] = ['学编程', '练思维', '创未来']
/**
 * 首页模块
 */
export const Index = () => {
  const { setShowRegisterDialog, isLogin, setShowQrcodeDialog, setVideoAssetLoaded, videoAssetLoaded, setIsLogin } =
    useAppStore()
  const [form] = useForm()
  const [loading, setLoading] = useState<boolean>(false)
  const [time, setTime] = useState<number>(0)
  const [showDown, setShowDown] = useState<boolean>(true)

  let timer: number
  useEffect(() => {
    if (time !== 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timer = window.setTimeout(() => {
        setTime(time - 1)
      }, 1000)
    }
    return () => {
      timer && clearTimeout(timer)
    }
  }, [time])

  useEffect(() => {
    console.log(showDown, '111')

    if (!showDown) {
      return
    }
    const hide = () => {
      setShowDown(false)
    }
    document.addEventListener('scroll', hide)
    return () => {
      document.removeEventListener('scroll', hide)
    }
  }, [showDown])

  useEffect(() => {
    const video: HTMLVideoElement = document.getElementById('3dVideo') as HTMLVideoElement
    if (video) {
      const startTime = new Date().getTime()
      // 开始播放
      video.addEventListener('canplay', () => {
        if (videoAssetLoaded) {
          video.play()
          return // 资源已加载
        }
        video.pause()
        if (new Date().getTime() - startTime < 1000) {
          // 防止加载页一闪而过
          setTimeout(() => {
            videoPreparePlay()
          }, 1000)
        } else {
          videoPreparePlay()
        }
      })
      // 播放结束
      video.addEventListener('ended', function () {
        video.currentTime = 6
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const videoPreparePlay = () => {
    const video: HTMLVideoElement = document.getElementById('3dVideo') as HTMLVideoElement
    video.play()
    setVideoAssetLoaded(true)
    localStorage.setItem(HOME_VIDEO_CACHE_KEY, 'ok')
  }

  // 发送验证码
  const handleGetCode = async () => {
    if (time !== 0) {
      return
    }
    const phoneNumber = form.getFieldValue('mobile')
    if (!phoneNumber || !checkPhoneNumber(phoneNumber.trim())) {
      return form.validateFields(['mobile'])
    }
    try {
      await callCaptcha({
        identity: phoneNumber,
        phoneNumber,
        success_callback: async (ticket: string, appid: string) => {
          try {
            // 发送验证码
            const res = await getAuthCode(phoneNumber, ticket, appid)
            const { status } = res
            if (/^2/.test(`${status}`)) {
              // message.success('验证码已发送')
              setTime(60)
              reportEvent(PAGE_MODULECLICK_EVENT, {
                module_id: EModuleID.HOME_PAGE,
                from_id: EFromID.HOME,
                triggle_id: EEventID.Verification,
              })
            } else {
              if (res.data.error_code) {
                message.error(errorParse(res.data.error_code))
              } else {
                message.error('获取验证码失败')
              }
            }
          } catch (error) {
            message.error('获取验证码失败')
          }
        },
        fail_callback: (err: any) => message.error('获取验证码失败'),
      })
    } catch (err) {
      message.error('获取验证码失败')
    }
  }

  const handleClickLogin = async () => {
    try {
      await form.validateFields()
      handleRegister()
    } catch (err) {
      console.log(err)
    }
  }

  const handleRegister = debounce(async () => {
    const phone = form.getFieldValue('mobile')
    const code = form.getFieldValue('code')
    setLoading(true)
    const res = await registry(phone, code)
    const { status } = res
    switch (status) {
      case 201: // 成功
        setShowRegisterDialog(false)
        setIsLogin(true)
        setShowQrcodeDialog(true)
        reportEvent(PAGE_MODULECLICK_EVENT, {
          module_id: EModuleID.HOME_PAGE,
          from_id: EFromID.HOME,
          triggle_id: EEventID.Receive,
        })
        break
      case 429:
        message.error('请求过于频繁')
        break
      default:
        if (res.data.error_code) {
          if (isSmsCodeError(res.data.error_code)) {
            form.setFields([{ name: 'code', errors: [`*${errorParse(res.data.error_code)}`] }])
          } else {
            message.error(errorParse(res.data.error_code))
          }
        } else {
          message.error('预约失败，请重试')
        }
        break
    }
    setLoading(false)
  }, 300)

  const phoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFields([{ name: 'mobile', errors: [] }])
  }

  return (
    <div className={styles.page} id={EModuleID.HOME_PAGE}>
      {showDown && (
        <div className={styles.downWrap}>
          <img className={styles.down1} src={down1} alt="" />
          <img className={styles.down2} src={down2} alt="" />
          <img className={styles.down3} src={down3} alt="" />
        </div>
      )}
      <div className={styles.lable} />

      <video
        id="3dVideo"
        className={styles.video}
        src="https://online-education.codemao.cn/444/1713439427580首屏OK_4.mp4"
        autoPlay={false}
        muted
        // loop
      />
      <div className={styles.homeFormContainer}>
        <div className={styles.rightForm}>
          <div className={styles.info}>
            <p>立即领取</p>
            <p>免费试听在线课</p>
          </div>
          <Form className={styles.registerWarp} form={form}>
            <Form.Item
              className={styles.mobile}
              name="mobile"
              getValueFromEvent={(e) => valDeal(e, 11)}
              validateTrigger="onSubmit"
              rules={[{ validator: checkPhone }]}
            >
              <Input
                className={styles.input}
                maxLength={11}
                onFocus={() => {
                  reportEvent(PAGE_MODULECLICK_EVENT, {
                    module_id: EModuleID.HOME_PAGE,
                    from_id: EFromID.HOME,
                    triggle_id: EEventID.Phone,
                  })
                }}
                onChange={(e) => phoneChange(e)}
                placeholder="请输入手机号码"
                allowClear
              />
            </Form.Item>

            <div className={styles.codeWarp}>
              <Form.Item
                name="code"
                getValueFromEvent={(e) => valDeal(e, 6)}
                validateTrigger="onSubmit"
                rules={[{ validator: checkCode }]}
              >
                <Input className={styles.input} maxLength={6} placeholder="请输入验证码" />
              </Form.Item>
              <div className={styles.codeBtn} onClick={handleGetCode}>
                {time === 0 ? '获取验证码' : `${time}s后重新发送`}
              </div>
            </div>
            <Form.Item className={styles.loginWrap}>
              <Button className={styles.loginBtn} htmlType="submit" loading={loading} onClick={handleClickLogin}>
                立即领取
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}
