import { useEffect, useRef } from 'react'
import { EModuleID, PAGE_VIDEO_EVENT } from 'src/constant/config'
import useAppStore from 'src/store'
import { reportEvent } from 'src/utils/sensors'

export default function useStayVideo(currentModule: EModuleID) {
  const { videoStop } = useAppStore()

  // 视频开始播放计时
  const videoStartRef = useRef(0)

  useEffect(() => {
    if (!videoStop) {
      videoStartRef.current = new Date().getTime()
    }

    if (videoStop) {
      if (videoStartRef.current === 0) {
        return
      }
      const totalTime = new Date().getTime() - videoStartRef.current

      if (totalTime > 1000) {
        reportEvent(PAGE_VIDEO_EVENT, {
          browsing_duration: totalTime / 1000,
        })
      }
      videoStartRef.current = 0
    }
  }, [videoStop])
}
