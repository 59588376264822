import { FC } from 'react'
import { reportEvent } from 'src/utils/sensors'
import { PAGE_EXPOSED_EVENT, EModuleID } from 'src/constant/config'
import cert1 from 'src/assets/images/brand_cert1.png'
import cert2 from 'src/assets/images/brand_cert2.png'
import cert3 from 'src/assets/images/brand_cert3.png'
import cert4 from 'src/assets/images/brand_cert4.png'
import styles from './index.module.scss'

/**
 * 品牌实力模块
 */
export const Index = () => (
  <>
    <div className={styles.container} id={EModuleID.BRAND_PAGE}>
      <div className={styles.title}>
        <div className={styles.icon} />
        品牌实力，为孩子保驾护航
      </div>
      <div className={styles.board}>
        <div className={styles.certList}>
          <img src={cert1} />
          <img src={cert2} />
          <img src={cert3} />
          <img src={cert4} />
        </div>
        <div className={styles.certTextList}>
          <span className={styles.text}>
            荣获<span className={styles.red}>吴文俊人工智能科学技术奖</span>
          </span>
          <span className={styles.text}>
            荣获<span className={styles.red}>国家高新技术企业</span>认定
          </span>
          <span className={styles.text}>
            荣获<span className={styles.red}>中国人工智能学会</span>常务理事会员单位
          </span>
          <span className={styles.text}>
            荣获<span className={styles.red}>5A级在线教育</span>服务认证
          </span>
        </div>
        <div className={styles.list}>{}</div>
        <span className={styles.listText}>
          目前点猫科技已累计申请
          <span className={styles.red}>700余</span>
          项专利，获
          <span className={styles.red}>2700余</span>项作品著作权、<span className={styles.red}>150项</span>
          计算机软件著作权，覆盖产品技术发明、产品外观设计等多类
        </span>
      </div>
    </div>
  </>
)
