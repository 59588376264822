import { create } from 'zustand'
import { EEventID, EFromID, EModuleID, HOME_VIDEO_CACHE_KEY } from 'src/constant/config'

interface IAppState {
  // 视频资源是否加载完成
  videoAssetLoaded: boolean
  setVideoAssetLoaded: (bool: boolean) => void
  // 是否显示注册框
  showRegisterDialog: boolean
  setShowRegisterDialog: (bool: boolean) => void
  // 是否显示成功领取框
  showQrcodeDialog: boolean
  setShowQrcodeDialog: (bool: boolean) => void
  // 是否领取过
  isLogin: boolean
  setIsLogin: (bool: boolean) => void
  // 周年庆视频声音是否开启
  soundOpen: boolean
  setSoundOpen: (bool: boolean) => void
  // 周年庆视频暂停
  videoStop: boolean
  setVideoStop: (bool: boolean) => void
  showStayModal: boolean
  setShowStayModal: (bool: boolean) => void
  registerDialogFrom?: EFromID
  setRegisterDialogFrom: (from: EFromID) => void
}

const useAppStore = create<IAppState>()((set, get) => ({
  videoAssetLoaded: !!localStorage.getItem(HOME_VIDEO_CACHE_KEY) || false,
  setVideoAssetLoaded: (bool: boolean) => {
    set({ videoAssetLoaded: bool })
  },
  showRegisterDialog: false,
  setShowRegisterDialog: (bool: boolean) => {
    set({ showRegisterDialog: bool })
  },
  showQrcodeDialog: false,
  setShowQrcodeDialog: (bool: boolean) => {
    set({ showQrcodeDialog: bool })
  },
  isLogin: false,
  setIsLogin: (bool: boolean) => {
    set({ isLogin: bool })
  },
  soundOpen: false,
  setSoundOpen: (bool: boolean) => {
    set({ soundOpen: bool })
  },
  videoStop: true,
  setVideoStop: (bool: boolean) => {
    set({ videoStop: bool })
  },
  showStayModal: false,
  setShowStayModal: (bool: boolean) => {
    set({ showStayModal: bool })
  },
  registerDialogFrom: EFromID.BOTTOM,
  setRegisterDialogFrom: (from: EFromID) => {
    set({ registerDialogFrom: from })
  },
}))

export default useAppStore
