import { EModuleID } from 'src/constant/config'
import styles from './index.module.scss'

/**
 * 证书模块
 */
export const Index = () => (
  <div className={styles.container} id={EModuleID.CERTIFICATE_PAGE}>
    <div className={styles.title}>
      <div className={styles.icon} />
      孩子升学，先人一步
    </div>

    <div className={styles.subTitle}>
      优秀的孩子可以通过高考强基计划和综合素质评价 进入
      <span style={{ color: '#FA322D', fontWeight: 800 }}>清华、北大等顶级名校</span>
    </div>

    <div className={styles.content}>
      <div className={styles.left} />
      <div className={styles.right} />
    </div>
  </div>
)
