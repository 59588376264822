import { FC, useEffect, useState } from 'react'
import { reportEvent } from 'src/utils/sensors'
import { PAGE_EXPOSED_EVENT, EModuleID } from 'src/constant/config'

import * as echarts from 'echarts'

import TopInfo from './components/top-info'
import RightInfo from './components/right-info'
import BottomInfo from './components/bottom-info'
import LeftInfo from './components/left-info'

import { getStudyEchart } from './config'
import styles from './index.module.scss'

/**
 * 学习模块
 */
export const Index = () => {
  const [currentId, setCurrentId] = useState<number>(0)

  useEffect(() => {
    const chartDom = document.getElementById('echarts')
    const myChart = echarts.init(chartDom)
    console.log(myChart.getWidth(), myChart.getHeight())
    myChart.setOption(getStudyEchart(myChart.getWidth()))
    myChart.on('mouseover', (params: any) => {
      // 鼠标移入事件处理函数
      params && params.data && setCurrentId(params.data.id)
    })
    myChart.on('mouseout', (params) => {
      setCurrentId(0)
    })
  }, [])

  return (
    <div className={styles.container} id={EModuleID.STUDY_PAGE}>
      <div className={styles.title}>
        <div className={styles.icon} />
        学编程，培养学习能力
      </div>
      <div className={styles.subTitle}>
        聚焦思维与能力培养 <span style={{ color: '#FA322D', fontWeight: 800 }}>“6E”教学法</span>打造完整学习闭环
      </div>
      <div className={styles.content}>
        <TopInfo id={currentId} />
        <RightInfo id={currentId} />
        <BottomInfo id={currentId} />
        <LeftInfo id={currentId} />

        <div id="echarts" className={styles.echarts} />
      </div>
    </div>
  )
}
