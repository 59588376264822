import { useEffect, useRef } from 'react'
import { EFromID } from 'src/constant/config'
import useAppStore from 'src/store/index'

export function useDocumentEvent() {
  const { showRegisterDialog, showQrcodeDialog, isLogin, setShowRegisterDialog, setRegisterDialogFrom } = useAppStore()

  let lastOperateTime = new Date().getTime()
  const registerTimerRef = useRef<NodeJS.Timeout>(null)
  // 显示注册框时间
  const SHOW_REGISTER_TIME = 15000

  useEffect(() => {
    const documentOperate = () => {
      const thisOperateTime = (lastOperateTime = new Date().getTime())

      if (registerTimerRef.current) {
        clearInterval(registerTimerRef.current)
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      registerTimerRef.current = setTimeout(function () {
        if (thisOperateTime === lastOperateTime) {
          console.log(isLogin, showRegisterDialog, showQrcodeDialog)
          if (!isLogin && !showRegisterDialog && !showQrcodeDialog) {
            setShowRegisterDialog(true)
            setRegisterDialogFrom(EFromID.POPUP)
          }
        }
      }, SHOW_REGISTER_TIME)
    }
    document.addEventListener('mousedown', documentOperate)
    document.addEventListener('scroll', documentOperate)
    document.addEventListener('wheel', documentOperate)
    document.addEventListener('mouseup', documentOperate)
    document.addEventListener('mousemove', documentOperate)
    documentOperate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      document.removeEventListener('mousedown', documentOperate)
      document.removeEventListener('scroll', documentOperate)
      document.removeEventListener('wheel', documentOperate)
      document.removeEventListener('mouseup', documentOperate)
      document.removeEventListener('mousemove', documentOperate)
    }
  }, [showRegisterDialog, showQrcodeDialog, isLogin])
}
