import styles from './index.module.scss'

const TextWrap = ({ title, subTitle1, subTitle2 }: { title: string; subTitle1: string; subTitle2: string }) => (
  <div className={styles.textWrap}>
    <p className={styles.title}>{title}</p>
    <p className={styles.subTitle}>{subTitle1}</p>
    <p className={styles.subTitle}>{subTitle2}</p>
  </div>
)
export default TextWrap
