import { FC } from 'react'
import { IStudyInfo, IStudyInfoProps } from 'src/apis/types'
import hover2 from 'src/assets/images/study_hover2.png'
import hover3 from 'src/assets/images/study_hover3.png'
import TextWrap from '../text-wrap'
import styles from './index.module.scss'

const studyInfo: IStudyInfo[] = [
  {
    id: 2,
    lightImg: hover2,
  },
  {
    id: 3,
    lightImg: hover3,
  },
]

// 右边信息
const RightInfo: FC<IStudyInfoProps> = (props: IStudyInfoProps) => {
  const { id } = props

  return (
    <div className={styles.board}>
      <div className={styles.contentTop}>
        <TextWrap title="探究 Explore" subTitle2="锻炼问题分解能力" subTitle1="分折效果，拆解目标" />
      </div>
      <div className={styles.contentBottom}>
        <TextWrap title="启发 Enlighten" subTitle2="建立新旧知识链接" subTitle1="采用启发式教学" />
      </div>
      <div className={styles.logo} />

      {id === studyInfo[0].id && (
        <div className={styles.topTips}>
          <img src={studyInfo[0].lightImg} alt="" className={styles.light} />
        </div>
      )}
      {id === studyInfo[1].id && (
        <div className={styles.bottomTips}>
          <img src={studyInfo[1].lightImg} alt="" className={styles.light} />
        </div>
      )}
    </div>
  )
}

export default RightInfo
