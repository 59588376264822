import { PAGE_EXPOSED_EVENT, EModuleID } from 'src/constant/config'

import styles from './index.module.scss'
/**
 * 许可证模块
 */
export const Index = () => (
  <div className={styles.container} id={EModuleID.LICENSE_PAGE}>
    <div className={styles.title} />
    <div className={styles.content}>
      <div className={styles.cert1} />
      <div className={styles.cert2} />
    </div>
  </div>
)
