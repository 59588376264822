import { FC } from 'react'
import { IStudyInfo, IStudyInfoProps } from 'src/apis/types'
import hover4 from 'src/assets/images/study_hover4.png'
import TextWrap from '../text-wrap'
import styles from './index.module.scss'

const studyInfo: IStudyInfo = {
  id: 4,
  lightImg: hover4,
}

// 下边信息
const BottomInfo: FC<IStudyInfoProps> = (props: IStudyInfoProps) => {
  const { id } = props

  return (
    <div className={styles.board}>
      <div className={styles.content}>
        <TextWrap title="创造 Elaborabe" subTitle2="调试纠错能力和批判性思维" subTitle1="建立程序模型，构建项目开发" />
      </div>
      <div className={styles.leftLogo} />
      <div className={styles.rightLogo} />
      {id === studyInfo.id && (
        <div className={styles.tips}>
          <img src={studyInfo.lightImg} alt="" className={styles.light} />
        </div>
      )}
    </div>
  )
}

export default BottomInfo
