import sensors from 'sa-sdk-javascript'
import { Base64 } from 'js-base64'
import config from './config'
import { isMobile } from './'

sensors.use('PageLeave', {
  custom_props: {
    client: isMobile ? 'Mobile' : 'PC',
  },
  heartbeat_interval_time: 5,
  max_duration: 5 * 24 * 60 * 60,
  isCollectUrl: () => true,
})

// 初始化
sensors.init({
  show_log: true,
  server_url: config.sensors.server_url,
  is_track_single_page: true,
  use_client_time: true,
  send_type: 'beacon',
  heatmap: {
    clickmap: 'default',
    scroll_notice_map: 'not_collect',
  },
})

// 神策项目名
const sensorsName = {
  product_name: config.sensors.product_name,
  project_name: config.sensors.project_name,
}

sensors.registerPage(sensorsName)

// 用于采集 $pageview 事件
sensors.quick('autoTrack', {
  platform: isMobile ? 'h5' : 'pc',
})

/**
 * 获取神策Data，用于请求接口头
 *
 * @export
 */
export async function getSensorsData() {
  const anonymousId = sensors.quick('getAnonymousID') as string
  const data = {
    anonymousId,
    ext_data: sensorsName,
  }
  return Base64.encode(JSON.stringify(data))
}

/**
 * 神策用户登录
 *
 * @export
 * @param {AuthEntity} user
 */
export function loginEvent(user: any) {
  sensors.login(`${user?.id}`)
  sensors.setProfile(user)
}

/**
 * 神策代码埋点追踪事件
 *
 * @export
 * @param {string} name - 事件名称
 * @param {*} [props={}] - 自定义属性
 */
export function reportEvent(name: string, props: any = {}) {
  sensors.track(name, props)
}
