import { createRoot } from 'react-dom/client'
import { setHtmlFont, qs_parse, isMobile } from 'src/utils/index'
import config from 'src/utils/config'
import { WebMonitor } from '@frontend/web-monitor'
import App from './app'

if (isMobile) {
  location.href = config.mobileHost
}

const container = document.getElementById('root')
const root = createRoot(container!)

try {
  new WebMonitor() // 默认不用传参
} catch (error) {
  console.log('qms监控系统 ==> ', error)
}

// sem记录搜索引擎跳转过来的关键字
const query = qs_parse(decodeURI(document.referrer))
const domain = document.referrer.split('/')[2]
const keyword = query.keyword || query.query || query.wd || query.q || query.word
if (!/codemao\.cn/.test(domain)) {
  sessionStorage.setItem('sem_source', JSON.stringify({ searching_keyword: keyword, searching_engine: domain }))
}

root.render(<App />)

setHtmlFont()
