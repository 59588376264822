/**
 * 系统配置信息
 */
export const pid = '4ceH5ekc' // 产品id

export const product_code = 'landpage' // 产品id

export const PAGE_EXPOSED_EVENT = 'PCProductModuleExposed' // 模块曝光事件
export const PAGE_MODULECLICK_EVENT = 'PCProductModuleClick' // 模块点击事件
export const PAGE_VIDEO_EVENT = 'PCProductVideoBrowsing' // 视频浏览事件

export enum EModuleID {
  HOME_PAGE = 'homePage', // 首页模块
  VIDEO_PAGE = 'videoPage', // 视频模块
  LICENSE_PAGE = 'licensePage', // 许可证模块
  STUDY_PAGE = 'studyPage', // 学习模块
  CERTIFICATE_PAGE = 'certificatePage', // 证书模块
  COURESE_PAGE = 'coursePage', // 课程模块
  TEACHER_PAGE = 'teacherPage', // 老师模块
  BRAND_PAGE = 'brandPage', // 品牌实力模块
}
export enum EFromID {
  HOME = 'Home',
  BOTTOM = 'Bottom',
  POPUP = 'Popup',
  VIDEO = 'Video',
}
export enum EEventID {
  From = 'From',
  Phone = 'Phone',
  Verification = 'Verification',
  Receive = 'Receive',
  Cancel = 'Cancel',
}

export const HOME_VIDEO_CACHE_KEY = 'homeVideoCache' // 首页视频是否缓存
