import { FC, useState, useEffect } from 'react'
import { Form, Input, Button, message } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { checkPhoneNumber } from '@frontend/doraemon'
import { checkVertifyCode } from 'src/utils/index'
import useAppStore from 'src/store/index'
import { callCaptcha } from 'src/utils/iris'
import { errorParse, isSmsCodeError } from 'src/utils/error-parse'
import { getAuthCode, registry } from 'src/apis/app'
import { debounce } from 'lodash-es'
import { reportEvent } from 'src/utils/sensors'
import { EEventID, EFromID, EModuleID, PAGE_MODULECLICK_EVENT } from 'src/constant/config'
import styles from './index.module.scss'

// 只保留数字
export const valDeal = (e: React.ChangeEvent<HTMLInputElement>, len: number) =>
  e.target.value.replace(/[^0-9]/g, '').slice(0, len)

// 验证码检验
export const checkCode = (_: any, val: any) =>
  new Promise<void>((resolve, reject) => {
    if (!val) {
      reject('*请输入验证码')
    } else if (!checkVertifyCode(val)) {
      reject('*验证码为6位数字，请正确输入')
    } else {
      resolve()
    }
  })
// 手机号码检验
export const checkPhone = (_: any, val: any) =>
  new Promise<void>((resolve, reject) => {
    if (!val) {
      reject('*请输入手机号码')
    } else if (!checkPhoneNumber(val)) {
      reject('*手机号错误，请重新输入')
    } else {
      resolve()
    }
  })

// 注册弹窗
const RegisterDialog = ({ currentModule }: { currentModule: EModuleID }) => {
  const { showRegisterDialog, setShowRegisterDialog, setShowQrcodeDialog, setIsLogin, registerDialogFrom } =
    useAppStore()
  const [form] = useForm()
  const [time, setTime] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  let timer: number
  useEffect(() => {
    if (time !== 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timer = window.setTimeout(() => {
        setTime(time - 1)
      }, 1000)
    }
    return () => {
      timer && clearTimeout(timer)
    }
  }, [time])

  useEffect(() => {
    if (showRegisterDialog) {
      reportEvent(PAGE_MODULECLICK_EVENT, {
        module_id: currentModule,
        from_id: registerDialogFrom,
        triggle_id: EEventID.From,
      })
    }
  }, [showRegisterDialog])
  const phoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFields([{ name: 'mobile', errors: [] }])
  }

  // 发送验证码
  const handleGetCode = async () => {
    if (time !== 0) {
      return
    }
    const phoneNumber = form.getFieldValue('mobile')
    if (!phoneNumber || !checkPhoneNumber(phoneNumber.trim())) {
      return form.validateFields(['mobile'])
    }
    try {
      await callCaptcha({
        identity: phoneNumber,
        phoneNumber,
        success_callback: async (ticket: string, appid: string) => {
          try {
            // 发送验证码
            const res = await getAuthCode(phoneNumber, ticket, appid)
            const { status } = res
            if (/^2/.test(`${status}`)) {
              // message.success('验证码已发送')
              setTime(60)
              reportEvent(PAGE_MODULECLICK_EVENT, {
                module_id: currentModule,
                from_id: registerDialogFrom,
                triggle_id: EEventID.Verification,
              })
            } else {
              if (res.data.error_code) {
                message.error(errorParse(res.data.error_code))
              } else {
                message.error('获取验证码失败')
              }
            }
          } catch (error) {
            message.error('获取验证码失败')
          }
        },
        fail_callback: (err: any) => message.error('获取验证码失败'),
      })
    } catch (err) {
      message.error('获取验证码失败')
    }
  }

  const handleClickLogin = async () => {
    try {
      await form.validateFields()
      handleRegister()
    } catch (err) {
      console.log(err)
    }
  }

  const handleRegister = debounce(async () => {
    const phone = form.getFieldValue('mobile')
    const code = form.getFieldValue('code')
    setLoading(true)
    const res = await registry(phone, code)
    const { status } = res
    switch (status) {
      case 201: // 成功
        setShowRegisterDialog(false)
        setIsLogin(true)
        setShowQrcodeDialog(true)
        reportEvent(PAGE_MODULECLICK_EVENT, {
          module_id: currentModule,
          from_id: registerDialogFrom,
          triggle_id: EEventID.Receive,
        })
        break
      case 429:
        message.error('请求过于频繁')
        break
      default:
        if (res.data.error_code) {
          if (isSmsCodeError(res.data.error_code)) {
            form.setFields([{ name: 'code', errors: [`*${errorParse(res.data.error_code)}`] }])
          } else {
            message.error(errorParse(res.data.error_code))
          }
        } else {
          message.error('预约失败，请重试')
        }
        break
    }
    setLoading(false)
  }, 300)

  const onClose = () => {
    form.resetFields()
    setTime(0)
    timer && clearTimeout(timer)
    setShowRegisterDialog(false)
    reportEvent(PAGE_MODULECLICK_EVENT, {
      module_id: currentModule,
      from_id: registerDialogFrom,
      triggle_id: EEventID.Cancel,
    })
  }

  return showRegisterDialog ? (
    <div className={styles.hiDialog}>
      <div className={styles.container}>
        <div className={styles.close} onClick={onClose} />
        <div className={styles.leftLogo} />
        <div className={styles.rightForm}>
          <div className={styles.info}>
            <p className={styles.title}>立即领取</p>
            <p>免费试听在线课程</p>
          </div>
          <Form className={styles.registerWarp} form={form}>
            <Form.Item
              className={styles.mobile}
              name="mobile"
              getValueFromEvent={(e) => valDeal(e, 11)}
              validateTrigger="onSubmit"
              rules={[{ validator: checkPhone }]}
            >
              <Input
                className={styles.input}
                maxLength={11}
                onChange={(e) => phoneChange(e)}
                onFocus={() => {
                  reportEvent(PAGE_MODULECLICK_EVENT, {
                    module_id: currentModule,
                    from_id: registerDialogFrom,
                    triggle_id: EEventID.Phone,
                  })
                }}
                placeholder="请输入手机号码"
                allowClear
              />
            </Form.Item>

            <div className={styles.codeWarp}>
              <Form.Item
                name="code"
                getValueFromEvent={(e) => valDeal(e, 6)}
                validateTrigger="onSubmit"
                rules={[{ validator: checkCode }]}
              >
                <Input className={styles.input} maxLength={6} placeholder="请输入验证码" />
              </Form.Item>
              <div className={styles.codeBtn} onClick={handleGetCode}>
                {time === 0 ? '获取验证码' : `${time}s后重新发送`}
              </div>
            </div>
            <Form.Item className={styles.loginWrap}>
              <Button className={styles.loginBtn} htmlType="submit" loading={loading} onClick={handleClickLogin}>
                立即领取
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  ) : null
}

export default RegisterDialog
