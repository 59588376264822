import { AxiosResponse } from 'axios'
import Http from '@mlz/axios'
import { errorCodeTips, errorStatusTips } from 'src/utils/error-tips'
import config from 'src/utils/config'

const AUTHORIZATION_TYPE_THREE = '3'

// 全局设置token与tokenType
Http.setReqInterceptor((config) => {
  config.headers = {
    ...config.headers,
    authorization_type: AUTHORIZATION_TYPE_THREE,
  }
  return config
})

// 设置全局响应拦截
Http.setResInterceptor(
  (response: AxiosResponse<any>) => {
    if (response.status === 401) {
      // goAuthorize()
    } else {
      const msg = errorStatusTips[response.status]
      if (msg) {
        console.log(msg)
      }
    }
    return response
  },
  (error) => {
    if (error.message === 'Network Error') {
      console.log('网络连接异常')
    }
    if (error.message.indexOf('timeout') !== -1) {
      console.log('网络超时')
    }
    if (error.response && error.response.data) {
      throw new Error(errorCodeTips[error.response.data.error_code])
    }
  }
)

export const marketingCenter = new Http(config.api.marketingCenter)
export const channelApi = new Http(config.api.channel)
