import { FC } from 'react'
import { IStudyInfo, IStudyInfoProps } from 'src/apis/types'
import hover5 from 'src/assets/images/study_hover5.png'
import hover6 from 'src/assets/images/study_hover6.png'
import TextWrap from '../text-wrap'
import styles from './index.module.scss'

const studyInfo: IStudyInfo[] = [
  {
    id: 5,
    lightImg: hover5,
  },
  {
    id: 6,
    lightImg: hover6,
  },
]

// 左边信息
const LeftInfo: FC<IStudyInfoProps> = (props: IStudyInfoProps) => {
  const { id } = props

  return (
    <div className={styles.board}>
      <div className={styles.contentTop}>
        <TextWrap title="迁移 Extend" subTitle2="锻炼想象力和创造力" subTitle1="运用所学知识举一反三" />
      </div>
      <div className={styles.contentBottom}>
        <TextWrap title="评估 Evaluate" subTitle2="提升表达和反思能力" subTitle1="分享和展示开发成果，" />
      </div>
      <div className={styles.logo} />

      {id === studyInfo[0].id && (
        <div className={styles.bottomTips}>
          <img src={studyInfo[0].lightImg} alt="" className={styles.light} />
        </div>
      )}
      {id === studyInfo[1].id && (
        <div className={styles.topTips}>
          <img src={studyInfo[1].lightImg} alt="" className={styles.light} />
        </div>
      )}
    </div>
  )
}

export default LeftInfo
