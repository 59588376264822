import { useEffect, useRef, useState } from 'react'
import { EModuleID, PAGE_EXPOSED_EVENT } from 'src/constant/config'
import { reportEvent } from 'src/utils/sensors'

export default function useScrollEvent() {
  const exposeModulesRef = useRef<EModuleID[]>([EModuleID.HOME_PAGE])
  const [currentModule, setCurrentModule] = useState<EModuleID>(EModuleID.HOME_PAGE)

  useEffect(() => {
    // 进入页面就上报曝光
    reportEvent(PAGE_EXPOSED_EVENT, {
      module_id: EModuleID.HOME_PAGE,
    })
  }, [])
  useEffect(() => {
    // 定义一个函数来获取页面的中间位置
    function getPageMiddlePosition() {
      // 页面滚动位置（垂直方向）
      const scrollPosition = window.scrollY
      // 窗口高度的一半
      const halfWindowHeight = window.innerHeight / 2
      // 页面中间位置
      const pageMiddlePosition = scrollPosition + halfWindowHeight
      return pageMiddlePosition
    }

    // 定义一个函数来判断页面中间位置处于哪个组件
    function findCurrentComponent() {
      // 获取页面的中间位置
      const pageMiddlePosition = getPageMiddlePosition()
      const moduleArray = Object.values(EModuleID)
      // 获取组件的元素和位置
      const components = moduleArray.map((id) => document.getElementById(id)) // 假设你的组件都有一个类为'component'
      // 遍历所有组件
      for (let i = 0; i < components.length; i++) {
        const component = components[i] as HTMLElement
        // console.log(component, 'component')

        // 获取组件的边界
        const rect = component.getBoundingClientRect()
        // 计算组件在页面中的位置
        const top = rect.top + window.scrollY
        const bottom = top + component.offsetHeight

        // 判断页面中间位置是否在组件的范围内
        if (pageMiddlePosition >= top && pageMiddlePosition <= bottom) {
          const exposeModules = exposeModulesRef.current
          setCurrentModule(component.id as EModuleID)
          if (exposeModules.includes(component.id as EModuleID)) {
            return
          } else {
            reportEvent(PAGE_EXPOSED_EVENT, {
              module_id: component.id,
            })
            exposeModulesRef.current = exposeModules.concat([component.id as EModuleID])
          }
          // 如果页面中间位置处于组件范围内，更新当前组件
          console.log(`当前中间位置处于组件: ${i + 1} ${component.id}`)
        }
      }
    }
    // 添加滚动事件监听
    window.addEventListener('scroll', findCurrentComponent)

    return () => {
      // 移除滚动事件监听
      window.removeEventListener('scroll', findCurrentComponent)
    }
  }, [])
  return currentModule
}
