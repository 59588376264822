import { FC } from 'react'
import { reportEvent } from 'src/utils/sensors'
import { PAGE_EXPOSED_EVENT, EModuleID } from 'src/constant/config'
import styles from './index.module.scss'

/**
 * 课程模块
 */
export const Index = () => (
  <div className={styles.container} id={EModuleID.COURESE_PAGE}>
    <div className={styles.title}>
      <div className={styles.icon} />
      适合不同年龄段孩子的课程体系
    </div>
    <div className={styles.cat} />

    <div className={styles.icon1} />
    <div className={styles.icon2} />
  </div>
)
