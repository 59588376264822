/**
 * 状态码提示
 */
export const errorStatusTips: Record<number, string> = {
  403: '接口权限不足',
  404: '接口地址不存在',
  500: '服务器异常',
}

/**
 * 错误码提示
 */
export const errorCodeTips: Record<string, string> = {}
