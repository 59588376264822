import { getUrlParams } from 'src/utils/index'
import { marketingCenter, channelApi } from './index'

/** marketingCenter **/

// 获取物料素材
export async function getModuleOrder(code: string): CResponse<any> {
  return await marketingCenter.get(`/material/${code}`)
}

/** channelApi **/

// 获取验证码
export async function getAuthCode(phone: string, ticket: string, appid: string) {
  return await channelApi.post('/admin/marketing/sms/captcha/new', {
    phone_number: phone,
    ticket,
    app_id: appid,
  })
}

// 注册
export async function registry(phone: string, code: string) {
  const { utm_source, utm_term, utm_content } = getUrlParams()
  console.log(utm_source, utm_term, utm_content)
  return await channelApi.post('/land/pages/registrys', {
    channel_param: utm_term || '',
    channel_source_param: utm_source || '',
    channel_url: location.href,
    key_word_id: Number.isNaN(Number(utm_content)) ? 0 : parseInt(utm_content, 10), // 该字段为int类型 如果utm_content为非数字字符串则传0
    phone_number: phone,
    sms_code: code,
    // 加入sem搜索引擎关键字参数
    ...JSON.parse(sessionStorage.getItem('sem_source') || '{}'),
  })
}
