import { FC, useEffect, useRef } from 'react'
import { initIris } from 'src/utils/iris'
import { EModuleID } from 'src/constant/config'
import RegisterDialog from 'src/components/register-dialog'
import QrcodeDialog from 'src/components/qrcode-dialog'
import useAppStore from 'src/store/index'
import Loading from 'src/components/loading'
import { useDocumentEvent } from 'src/hooks/useDocumentEvent'
import useStayVideo from 'src/hooks/useStayVideo'

import useScrollEvent from 'src/hooks/useScrollEvent'
import StayModal from 'src/components/stay-modal'
import { Index as HomePage } from './components/homePage'
import { Index as VideoPage } from './components/videoPage'
import { Index as LicensePage } from './components/licensePage'
import { Index as StudyPage } from './components/studyPage'
import { Index as CertificatePage } from './components/certificatePage'
import { Index as CoursePage } from './components/coursePage'
import { Index as TeacherPage } from './components/teacherPage'
import { Index as BrandPage } from './components/brandPage'
import { Index as Footer } from './components/footer'
import styles from './index.module.scss'

const Home: FC = () => {
  const { videoAssetLoaded, soundOpen, setVideoStop } = useAppStore()

  useDocumentEvent()
  const currentModule = useScrollEvent()
  useStayVideo(currentModule)

  useEffect(() => {
    iris()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    // 视频模块自动播放
    if (EModuleID.VIDEO_PAGE === currentModule) {
      setVideoStop(false)
    }
    // 从视频模块滚动到上下文模块时，关闭视频
    if (EModuleID.VIDEO_PAGE !== currentModule) {
      setVideoStop(true)
    }
  }, [currentModule])

  async function iris() {
    await initIris()
  }

  return (
    <>
      <HomePage />
      <VideoPage />
      <LicensePage />
      <StudyPage />
      <div className={styles.wrap}>
        <CertificatePage />
        <CoursePage />
        <TeacherPage />
      </div>
      <BrandPage />
      <Footer currentModule={currentModule} />
      {/* <Entity moduleName={pageNames[pageIndex]} /> */}
      <RegisterDialog currentModule={currentModule}/>
      <QrcodeDialog />
      {/* <StayModal /> */}
      {/* 显示loading页时，同步加载资源 */}
      {!videoAssetLoaded && <Loading />}
    </>
  )
}

export default Home
