import { FC } from 'react'
import { reportEvent } from 'src/utils/sensors'
import { PAGE_EXPOSED_EVENT, EModuleID } from 'src/constant/config'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper/modules'
import SwiperCore from 'swiper'

import main1 from 'src/assets/images/teacher_p1.png'
import main2 from 'src/assets/images/teacher_p2.png'
import main3 from 'src/assets/images/teacher_p3.png'
import sub1 from 'src/assets/images/teacher_sub1.png'
import sub2 from 'src/assets/images/teacher_sub2.png'
import sub3 from 'src/assets/images/teacher_sub3.png'
import sub4 from 'src/assets/images/teacher_sub4.png'
import line from 'src/assets/images/teacher_line.png'

import styles from './index.module.scss'
import 'swiper/scss'
import 'swiper/scss/pagination'
SwiperCore.use([Pagination, Autoplay])

interface IMainTeacher {
  id: number
  content: any
}
const main_teacer: IMainTeacher[] = [
  {
    id: 1,
    content: main1,
  },
  {
    id: 2,
    content: main2,
  },
  {
    id: 3,
    content: main3,
  },
]
interface ISubTeacher {
  id: number
  img: any
  title: string
  name: string
  school: string
}
const sub_teacher: ISubTeacher[] = [
  {
    id: 1,
    img: sub1,
    title: '编程猫信奥竞赛负责人',
    name: '陈学康老师',
    school: '毕业于浙江大学',
  },
  {
    id: 2,
    img: sub2,
    title: '编程猫教学团队负责人',
    name: '大麦老师',
    school: '毕业于哈佛大学教育学院',
  },
  {
    id: 3,
    img: sub3,
    title: '编程猫教研工作室负责人',
    name: '苏扬扬老师',
    school: '香港大学教育学博士',
  },
  {
    id: 4,
    img: sub4,
    title: '编程猫教研工作室负责人',
    name: '吴嘉曈老师',
    school: '哈佛大学教育学硕士',
  },
]
/**
 * 老师模块
 */
export const Index = () => (
  <div className={styles.container} id={EModuleID.TEACHER_PAGE}>
    <div className={styles.title}>
      <div className={styles.icon} />
      名校专家教研团队，帮助孩子更好提升
    </div>
    <div className={styles.content}>
      <div className={styles.main}>
        <Swiper
          className={styles.teacherSwiper}
          slidesPerView={1}
          loop
          autoplay={{
            delay: 3000,
            pauseOnMouseEnter: true,
          }}
          pagination={{
            clickable: true,
          }}
          speed={800}
          direction="horizontal"
          oneWayMovement
          allowTouchMove={false}
        >
          {main_teacer.map((item) => (
            <SwiperSlide key={item.id}>
              <div className={styles.imgWrap}>
                <img src={item.content} alt="" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={styles.subInfo}>
        {sub_teacher.map((item) => (
          <div key={item.id} className={styles.info}>
            <img src={item.img} alt="" />
            <span
              style={{
                fontSize: 13,
                transform: `scale(${(document.documentElement.offsetWidth || 1920) / 1920})`,
                transformOrigin: '0 50%',
              }}
              className={styles.subTitle}
            >
              {item.title}
            </span>
            <div className={styles.intro}>
              <p className={styles.name}>{item.name}</p>
              <img src={line} alt="" />
              <p className={styles.inTitle}>{item.title}</p>
              <p className={styles.school}>{item.school}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)
