import * as iris from '@frontend/iris'
import { message } from 'antd'
import { pid } from 'src/constant/config'
import { getSensorsData } from 'src/utils/sensors'
import config from 'src/utils/config'

// iris 实例
export const irisIns: any = iris
export let irisAuth: any = null

// 初始化
export const initIris = async () => {
  const custom_headers: any = {
    'SENSORS-DATA': getSensorsData(),
  }
  irisIns.init({
    env: getIrisEnv(config.env),
    domain: location.href.includes('http://localhost:') ? '' : '.codemao.cn', // 本地调试时需要设置为空; 生成环境只能为.codemao.cn
    custom_headers,
  })

  irisAuth = irisIns.auth.init({
    pid: pid,
    appid: '',
    product_code: 'transaction',
    platform: 'H5',
  })
}

const getIrisEnv = (env: string) => {
  let irisEnv = ''
  switch (env) {
    case 'dev':
      irisEnv = 'dev'
      break
    case 'prod':
      irisEnv = 'prod'
      break
    case 'staging':
      irisEnv = 'staging'
      break
    case 'test':
      irisEnv = 'api-test'
      break
  }
  return irisEnv
}

// 打开图形验证码
export const callCaptcha = async (config: any) => {
  const codemaoCaptcha = new irisIns.captcha.CodemaoCaptcha({
    pid: pid,
  })
  try {
    const captcha = await codemaoCaptcha.get_captcha({
      ...config,
      success_callback: (ticket: string, appid: string) => {
        config.success_callback(ticket, appid)
      },
      // 验证失败后的回调
      fail_callback: (error: any) => {
        throw new Error(error)
      },
    })
    if (captcha) {
      await captcha.init()
      captcha.show()
    }
  } catch (error) {
    console.log(error)
    message.error('网络异常，请检查网络连接是否正常！')
  }
}
