import { FC } from 'react'
import { toArray } from 'lodash-es'
import styles from './index.module.scss'

const Loading: FC = () => (
  <div className={styles.page}>
    <div className={styles.content}>
      <div className={styles.logo} />
      <div className={styles.title}>
        编程猫，让孩子享受编程创造的乐趣
        <div className={styles.icon} />
      </div>
      <div className={styles.subTitle}>
        {toArray('学编程/练思维/解问题/创未来').map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={index}>{item}</span>
        ))}
      </div>
    </div>
  </div>
)
export default Loading
