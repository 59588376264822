import { FC } from 'react'
import useAppStore from 'src/store/index'
import styles from './index.module.scss'

// 注册成功-显示公众号二维码弹窗
const QrcodeDialog: FC = () => {
  const { showQrcodeDialog, setShowQrcodeDialog } = useAppStore()

  return showQrcodeDialog ? (
    <div className={styles.hiDialog}>
      <div className={styles.container}>
        <div
          className={styles.close}
          onClick={() => {
            setShowQrcodeDialog(false)
          }}
        />
        <div className={styles.leftLogo} />
        <div className={styles.rightInfo}>
          <div className={styles.top}>
            <div className={styles.icon} />
            恭喜您领取成功！
          </div>
          <div className={styles.content}>
            <p>
              课程顾问将在<span style={{ color: '#FA322D' }}>3日内</span>与您电话联系，
            </p>
            <p>为孩子安排合适课程，请您注意接听</p>
            <div className={styles.qrcode} />
          </div>
          <div className={styles.bottom}>关注编程猫公众号更多资料、好礼等您拿！</div>
        </div>
      </div>
    </div>
  ) : null
}
export default QrcodeDialog
