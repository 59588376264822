const userAgent = navigator.userAgent
export const isMobile = /(iPhone|iPad|ios|Android|Mobile)/i.test(userAgent)

// 验证码校验
export const checkVertifyCode = (code: string): boolean => /[0-9]{6}/.test(code)

export const getUrlParams = (): any => {
  const url = window.location.search
  if (url === '') {
    return {}
  }
  const pairs = location.search.split('?')[1].split('&')
  const obj: any = {}
  pairs.forEach(function (item) {
    // 只查找到第一个"＝"符号，这样就不会把token中等号也裁剪掉
    const index = item.indexOf('=')
    const name = item.substr(0, index)
    let value = item.substr(index + 1)
    value = decodeURIComponent(value)
    obj[name] = value
  })
  return obj
}

export function qs_parse(url: string = location.href): any {
  const query_match = url.replace(/[^?]*\?/, '').match(/([^?=&]+)(=([^&]*))/g)
  return (
    !!query_match &&
    query_match.reduce((a: any, v: any) => ((a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)), a), {})
  )
}

// 高度适配
export const setHtmlFont = () => {
  const docEl = document.documentElement
  const dpr = window.devicePixelRatio || 1

  // adjust body font size
  const setBodyFontSize = () => {
    if (document.body) {
      document.body.style.fontSize = `${16 * dpr}px`
    } else {
      document.addEventListener('DOMContentLoaded', setBodyFontSize)
    }
  }
  setBodyFontSize()

  const setRemUnit = () => {
    let rem = 16
    const maxHeight = 1920 // 设计稿尺寸
    // const maxScreenWidth = 1080 // 设计给定需要适配的最大屏幕
    // const maxRem = (maxScreenWidth / maxWidth) * 100
    const offsetHeight = docEl!.offsetWidth
    rem = (offsetHeight / maxHeight) * 100
    console.log(offsetHeight, rem, 'rem')

    // rem = offsetWidth > maxScreenWidth ? maxRem : rem
    docEl!.style.fontSize = `${rem}px`
  }

  setRemUnit()

  // reset rem unit on page resize
  window.addEventListener('resize', setRemUnit)
  window.addEventListener('pageshow', function (e) {
    if (e.persisted) {
      setRemUnit()
    }
  })
}
